import React from "react";
import commonbannerimg from "../assets/commonbannerimg.jpeg";
import sco1 from "../assets/Blogs/sco1.jpeg";
import sco2 from "../assets/Blogs/sco2.jpeg";
import sco3 from "../assets/Blogs/sco3.jpeg";

import sco4 from "../assets/Blogs/sco4.jpeg";

import aboutus123 from "../assets/About us/aboutusimage1-removebg-preview.png";
import Button from "./common/Button";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
    const navigate = useNavigate();
    const handleClick = (blog) => {
        console.log("Blog Image:", blog.image);
        console.log("Blog Description:", blog.description);
        navigate("/blogsPage");
    };
  

  // Dynamic blog data
  const blogData = [
    {
      title: "10 Reasons to invest in SEO copywriting services",
      description:
        "A professional website conveys trust in two main ways: it exudes credibility and is friendly and communicative in terms of design. It therefore develops an online presence which appears credible and dependable to any visitor. The web composition should depict a decent picture that says brand values and thus guarantees clients that it will change over guests into long-lasting clients.",
      image: sco1,
      alignLeft: true,
    },
    {
      title: "Why User Experience Matters in Website Design",
      description:
        "User experience is at the core of effective website design. Websites that prioritize user engagement ensure visitors can easily find what they need. A seamless, intuitive interface builds trust and encourages users to explore further.",
      image: sco1,
      alignLeft: false,
    },
    {
      title: "10 Reasons to invest in SEO copywriting services",
      description:
        "A professional website conveys trust in two main ways: it exudes credibility and is friendly and communicative in terms of design. It therefore develops an online presence which appears credible and dependable to any visitor. The web composition should depict a decent picture that says brand values and thus guarantees clients that it will change over guests into long-lasting clients.",
      image: sco1,
      alignLeft: true,
    },
    {
      title: "10 Reasons to invest in SEO copywriting services",
      description:
        "A professional website conveys trust in two main ways: it exudes credibility and is friendly and communicative in terms of design. It therefore develops an online presence which appears credible and dependable to any visitor. The web composition should depict a decent picture that says brand values and thus guarantees clients that it will change over guests into long-lasting clients.",
      image: sco1,
      alignLeft: false,
    },
  ];

  return (
        <>
    <div className="bg-gray-50 text-gray-800 mt-[64px]">
      {/* Header Section */}
      <div
        className="bg-cover bg-center text-white h-[300px] md:h-56 p-8 flex flex-col justify-center items-center relative"
        style={{
          backgroundImage: `url(${commonbannerimg})`,
        }}>
        <h2 className="text-4xl font-bold relative z-10">Our Blogs</h2>
        <p className="text-sm mt-2 relative z-10">Home / Our Blogs</p>
      </div>

      <div className="text-center py-8 bg-white">
        <p className="font-semibold text-sm md:text-base tracking-wide text-orange-600">
          \ Our Blogs \
        </p>
        <h2 className="text-2xl md:text-4xl font-bold mt-2">What We Do</h2>
      </div>

      {/* Blog Section */}
      {blogData.map((blog, index) => (
        <section
          key={index}
          className={`flex flex-col lg:flex-row items-center justify-between  bg-white gap-12 px-4 lg:px-20 py-16 ${
            blog.alignLeft ? "" : "lg:flex-row-reverse "
          }`}>
          {/* Image Section */}
          <div className="relative">
            <div className="rounded-md w-24 h-24 absolute -left-12 -top-12 z-0">
              <img src={aboutus123} alt="Decoration" className="w-24 h-36" />
            </div>
            <div className="rounded-md w-36 h-36 bg-custom-orenge absolute -left-3 -top-3 z-10"></div>
            <div className="relative z-20">
              <img
                src={blog.image}
                alt={blog.title}
                className={`md:w-96 rounded-md w-[17rem] ${
                  blog.alignLeft ? "animate-fadeInLeft" : "animate-fadeInRight"
                }`}
              />
            </div>
            <div className="rounded-md w-24 h-24 absolute md:left-[21rem] md:top-[6rem] left-[14rem] top-[2rem] z-0">
              <img src={aboutus123} alt="Decoration" className="" />
            </div>
            <div className="rounded-md w-36 h-36 bg-custom-gray absolute md:left-64 md:top-[5rem] left-[9rem] top-[1rem] z-10"></div>
          </div>

                    {/* Text Section */}
                    <div className="max-w-xl text-center lg:text-left">
                        <p className="text-2xl sm:text-3xl lg:text-2xl font-bold text-gray-900">
                            {blog.title}
                        </p>
                        <p className="text-gray-600 mt-4">{blog.description}</p>
                        <div className="mt-8 flex flex-wrap gap-12 justify-center lg:justify-start">
                            <Button
                                type="button"
                                text="Read more"
                                className="bg-custom-orenge p-2 px-5 rounded-md shadow-md text-white"
                                onClick={() => handleClick(blog)}
                            />
                        </div>
                    </div>
                </section>
            ))}
        </div>
        </>
    );
};

export default Blogs;
