import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/Takmon Boost full.svg";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false); // Mobile menu toggle
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Desktop dropdown toggle
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false); // Mobile dropdown toggle
  const [isSeoDropdownOpen, setIsSeoDropdownOpen] = useState(false); // Nested dropdown for SEO Services

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menuItems = [
    { name: "Home", to: "/" },
    { name: "Services", to: "/services" }, // Dropdown item
    { name: "Packages", to: "/package" },
    { name: "Blogs", to: "/blogs" },
    // { name: "Quotation", to: "/quotation" },
    { name: "About Us", to: "/about" },
    { name: "Contact Us", to: "/contact" },
  
  ];

  const serviceDropdownItems = [
    {
      name: "SEO Services",
      to: "#",
      nestedItems: [
        { name: "Local Business SEO", to: "/services/seo/local-business" },
        { name: "Outsource SEO", to: "/services/seo/outsource" },
        { name: "Small Business SEO", to: "/services/seo/small-business" },
        { name: "OnPage SEO", to: "/services/seo/onpage" },
        { name: "Off-Page SEO", to: "/services/seo/offpage" },
        { name: "Competitor Analysis SEO", to: "/services/seo/competitor" },
        { name: "Technical SEO", to: "/services/seo/technical" },
        { name: "White Label Backlink Building Services", to: "/services/seo/white-label" },
        { name: "CMS SEO", to: "/services/seo/cms" },
      ],
    },
    { name: "SMM Services", to: "/services/smm" },
    { name: "Social Media Management", to: "/services/social-media" },
    { name: "SMO Services", to: "/services/smo" },
    { name: "PPC Services", to: "/services/ppc" },
    { name: "Content Marketing", to: "/services/content" },
    { name: "Web Design Services", to: "/services/web-design" },
  ];

  const currentPath = window.location.pathname;

  return (
    <div className="bg-white mt-0 ">
      <nav className="bg-white w-full z-30 top-0 start-0 scroll-smooth fixed mt-0  ">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            {/* Logo */}
            <div className="flex items-center">
              <img
                src={logo}
                alt="Logo"
                className="flex items-center w-36 h-20 object-contain"
              />
            </div>

            {/* Desktop Menu */}
            <div className="hidden md:flex items-center space-x-10 relative">
              <div className="ml-10 flex items-baseline space-x-3">
                {menuItems.map((item) =>
                  item.name === "Services" ? (
                    <div
                      key={item.name}
                      className="relative"
                      onMouseEnter={() => setIsDropdownOpen(true)}
                      onMouseLeave={() => setIsDropdownOpen(false)}
                    >
                      <Link
                        to="#"
                        className={`${
                          currentPath.includes("/services")
                            ? "text-white bg-custom-orenge font-bold"
                            : "text-black font-semibold hover:text-white hover:bg-custom-orenge"
                        } flex items-center px-3 py-2 rounded-md text-base `}
                      >
                        {item.name}
                        <svg
                          className={`w-4 h-4 ml-2 transition-transform ${
                            isDropdownOpen ? "rotate-180" : ""
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </Link>
                      {isDropdownOpen && (
                        <div className="absolute z-10 left-0 mt-0 bg-white rounded-md shadow-lg w-40">
                          {serviceDropdownItems.map((dropdownItem) =>
                            dropdownItem.nestedItems ? (
                              <div
                                key={dropdownItem.name}
                                className="relative"
                                onMouseEnter={() => setIsSeoDropdownOpen(true)}
                                onMouseLeave={() => setIsSeoDropdownOpen(false)}
                              >
                                <Link
                                  to="#"
                                  className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-orange-600 rounded-md"
                                >
                                  {dropdownItem.name}
                                  <svg
                                    className={`w-4 h-4 ml-2 transition-transform ${
                                      isSeoDropdownOpen ? "rotate-180" : ""
                                    }`}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M19 9l-7 7-7-7"
                                    />
                                  </svg>
                                </Link>
                                {isSeoDropdownOpen && (
                                  <div className="absolute left-full top-0 mt-0 bg-white rounded-md shadow-lg w-52">
                                    {dropdownItem.nestedItems.map((seoItem) => (
                                      <Link
                                        key={seoItem.name}
                                        to={seoItem.to}
                                        className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-orange-600 rounded-md"
                                      >
                                        {seoItem.name}
                                      </Link>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <Link
                                key={dropdownItem.name}
                                to={dropdownItem.to}
                                className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-orange-600 rounded-md"
                              >
                                {dropdownItem.name}
                              </Link>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <Link
                      key={item.name}
                      to={item.to}
                      className={`${
                        currentPath === item.to
                          ? "text-white bg-custom-orenge font-bold"
                          : "text-black font-semibold hover:text-white hover:bg-custom-orenge"
                      } px-3 py-2 rounded-md text-base`}
                    >
                      {item.name}
                    </Link>
                  )
                )}
              </div>
            </div>

            {/* Mobile Menu Button */}
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={toggleMenu}
                className="inline-flex items-center justify-center p-2 rounded-md text-red-400 hover:text-white hover:bg-custom-orenge"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={
                      isOpen
                        ? "M6 18L18 6M6 6l12 12"
                        : "M4 6h16M4 12h16M4 18h16"
                    }
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <div className="md:hidden px-2 pt-2 pb-3 space-y-1">
            {menuItems.map((item) =>
              item.name === "Services" ? (
                <div key={item.name}>
                  <div className="relative">
                    <button
                      onClick={() => setIsMobileDropdownOpen(!isMobileDropdownOpen)}
                      className="flex items-center justify-between w-45 text-black hover:text-custom-orenge px-3 py-2 rounded-md text-base font-medium"
                    >
                      {item.name}
                      <svg
                        className={`w-4 h-4 ml-2 transition-transform ${isMobileDropdownOpen ? "rotate-180" : ""
                          }`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </button>
                    {isMobileDropdownOpen && (
                      <div className="absolute left-0 mt-2 bg-gray-50 border border-gray-200 rounded-md shadow-lg w-60">
                        {serviceDropdownItems.map((dropdownItem) => (
                          <Link
                            key={dropdownItem.name}
                            to={dropdownItem.to}
                            className="block px-3 py-2 text-sm text-black hover:bg-custom-orenge hover:text-white rounded-md"
                          >
                            {dropdownItem.name}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <Link
                  key={item.name}
                  to={item.to}
                  className="text-black hover:text-custom-orenge block px-3 py-2  rounded-md text-base font-medium"
                >
                  {item.name}
                </Link>
              )
            )}
          </div>
        )}
      </nav>
    </div>
  );
};

export default Header;

