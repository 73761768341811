import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Autoplay, Pagination } from "swiper/modules";
import bannerimg1 from "../../../assets/BannerImages/bannerimg1.jpeg";
import bannerimg3 from "../../../assets/BannerImages/bannerimg3.jpeg";
import Button from "../../common/Button";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const navigate = useNavigate();

  // Handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };
   
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleButtonClick = () => {
    console.log("Explore More button clicked!"); // Example action
    // Add your desired functionality here
    navigate("/offer");
  };

  const slides = [
    {
      title: "Marketing Consulting’s",
      description: "Driving Growth with Marketing Insights",
      text: "Unlock business potential by leveraging marketing insights to drive strategic growth, enhance customer engagement, and optimize decision-making for success.",
      image: bannerimg3,
      tags: ["Development", "Branding", "Marketing", "SEO"],
    },
    {
      title: "Business Growth",
      description: "Strategies for Extraordinary Business Growth",
      text: "Unlock extraordinary business growth with innovative strategies: focus on market trends, customer insights, digital transformation, strategic partnerships, and continuous improvement to achieve sustainable success..",
      image: bannerimg1,
      tags: ["Strategy", "Innovation", "Growth", "Planning"],
    },
  ];

  return (
    <>
    <section className="relative bg-cover bg-center mt-10 pt-0 ">
      <div className="container mx-auto">
        <Swiper
          modules={[Autoplay, Pagination]}
          autoplay={{ delay: 4000 }}
          loop={true}
          slidesPerView={1}
          className="w-full"
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <div
                className="custom-slide text-white"
                style={{
                  backgroundImage: isLargeScreen
                    ? `url(${slide.image})`
                    : "none", // Hide the background image for smaller screens
                }}
              >
                {/* Rotated Gradient Box */}
                {/* <div
                className="absolute ml-20 xl:top-44 lg:top-16 lg:left-96  md:top-4 md:left-0 left-96 w-20 h-20 rounded-2xl rotate-45 "
                style={{
                  background: "linear-gradient(45deg, #FF6606, #F79911)",
                }}
              ></div> */}

                <div className="grid grid-cols-12 gap-4 items-center w-full px-6 ">
                  {/* Left Side Content */}
                  <div className="col-span-12 lg:col-span-7 p-6 rounded-lg lg:bg-transparent text-center m-auto lg:text-left max-w-xl w-full">
                    <h1
                      className="font-bold animate-fadeInLeft mt-16 md:mt-auto  leading-[1.2] lg:text-3xl md:text-[30px] text-[40px]"
                      style={{
                        background:
                          "linear-gradient(to right, #404B52, #F97316, #DC2626)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}>
                      {slide.title}
                    </h1>
                    {/* Horizontal Line for Separation */}
                    <div className=" relative w-80 h-[4px] bg-gradient-to-r from-orange-400 to-orange-600 mt-3 mb-5 mx-auto lg:mx-0 rounded-r-lg">
                      <div
                        className="absolute hidden lg:flex -right-40 bottom-0 w-20 h-20 rounded-2xl rotate-45 animate-rotate "
                        style={{
                          background:
                            "linear-gradient(45deg, #FF6606, #F79911)",
                        }}></div>
                    </div>

                    <h className="font-bold leading-[1.2] text-5xl sm:text-2x lg:text-4xl text-custom-blue pt-3 animate-fadeInUp opacity-1">
                      {slide.description}
                    </h>
                    <p className="mt-6 text-xl text-gray-900">{slide.text}</p>
                    <div className="relative">
                      <Button
                        type="search"
                        className="mt-6 px-6 animate-fadeInDown py-2 rounded-lg text-white bg-gray-700"
                        text="Explore More"
                        onClick={() => handleButtonClick()}
                      />
                      <div
                        className="absolute  hidden lg:flex lg:right-40 -bottom-12 w-20 h-20 rounded-2xl rotate-45 animate-rotate"
                        style={{
                          background:
                            "linear-gradient(45deg, #FF6606, #F79911)",
                        }}></div>
                    </div>
                    {/* Additional Rotated Gradient Box */}
                  </div>

                  {/* Right Side Content */}
                  <div className="hidden lg:block col-span-12 lg:col-span-4 relative">
                    {/* Text Badges */}
                    <div className="absolute top-20 left-96 px-4 py-2  bg-gray-900 text-white text-sm rounded-full animate-fadeInDown">
                      SEO
                    </div>
                    <div className="absolute top-[-150px] left-[-10px] px-4 py-2 bg-green-600 text-white text-sm rounded-full animate-fadeInDown">
                      Development
                    </div>
                    <div className="absolute -top-8 -left-40 px-4 py-2 bg-red-400 text-white text-sm rounded-full animate-scroll ">
                      Branding
                    </div>
                    <div className="absolute bottom-44 right-3 px-4 py-2  bg-blue-900 text-white text-sm rounded-full animate-sparkle">
                      Marketing
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
    </>
  );
};

export default Banner;


