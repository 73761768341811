import React from 'react'
import bannerimg from "../../../assets/BannerImages/bannerimage1.png"

const Professional = () => {
    return (
        // <section className="flex flex-col lg:flex-row items-center justify-between gap-12 px-6 lg:px-20 py-12">
        //     {/* Left Image Section */}
        //     <div className="relative">
        //         {/* Orange Background Shape */}
        //         <div className="absolute bg-orange-500 w-64 h-64 lg:w-80 lg:h-80 rounded-2xl -z-10"></div>
        //         {/* Main Image */}
        //         {/* Main Image */}
        //         <img
        //             src={bannerimg} // Replace with your image URL or import
        //             alt="Team Collaboration"
        //             className="relative w-[500px] h-[500px] lg:w-[600px] lg:h-[600px]   object-cover"
        //         />


        //     </div>

        //     {/* Right Text Section */}
        //     <div className="max-w-xl text-center lg:text-left">
        //         <h3 className="text-orange-500 font-semibold text-lg">
        //             \Professional Approach\
        //         </h3>
        //         <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 mt-2">
        //             Generate 100% Traffic <br /> On Your Website
        //         </h2>
        //         <p className="text-gray-600 mt-4">
        //             Lorem Ipsum is simply dummy text of the printing and typesetting
        //             industry. Lorem Ipsum has been the industry's standard dummy text
        //             ever since the 1500s, when an unknown printer took a galley of type
        //             and scrambled it to make a type specimen book.
        //         </p>

        //         {/* Bullet Points */}
        //         <ul className="mt-6 space-y-4">
        //             <li className="flex items-center gap-3">
        //                 <div className="w-5 h-5 bg-custom-gray rounded-full flex items-center justify-center text-white font-bold  p-2">
        //                     →
        //                 </div>
        //                 <span className=" text-2xl lg:text-xl font-bold text-gray-900 ">
        //                     Evolving Customer Expectations
        //                 </span>
        //             </li>
        //             <li className="flex items-center gap-3">
        //                 <div className="w-5 h-5 bg-custom-gray rounded-full flex items-center justify-center text-white font-bold  p-2">
        //                     →
        //                 </div>
        //                 <span className=" text-2xl lg:text-xl font-bold text-gray-900">
        //                     Automated And Paperless Seamless
        //                 </span>
        //             </li>
        //         </ul>

        //         {/* Statistics */}
        //         <div className="mt-8 flex flex-wrap gap-24 justify-center lg:justify-start">
        //             <div className="text-center">
        //                 <h3 className="text-2xl font-bold text-gray-900">90%</h3>
        //                 <p className="text-gray-600">Client Satisfied</p>
        //             </div>
        //             <div className="text-center">
        //                 <h3 className="text-2xl font-bold text-gray-900">90%</h3>
        //                 <p className="text-gray-600">Finance Consulting</p>
        //             </div>
        //         </div>
        //     </div>
        // </section>
        <section className="flex flex-col lg:flex-row items-center justify-between gap-12 px-4 lg:px-20 py-12">
        {/* Left Image Section */}
        <div className="relative">
          {/* Orange Background Shape */}
          <div className="absolute w-64 h-64 lg:w-80 lg:h-80 rounded-2xl -z-10"></div>
  
          {/* Main Image */}
          <img
            src={bannerimg} // Replace with your image URL or import
            alt="Team Collaboration"
            className="relative w-[300px] h-[300px] sm:w-[400px] sm:h-[400px] lg:w-[600px] lg:h-[600px]  object-cover"
          />
        </div>
  
        {/* Right Text Section */}
        <div className="max-w-xl text-center lg:text-left">
          <h3 className="text-orange-500 font-semibold text-lg">
            \Professional Approach\
          </h3>
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-900 mt-2">
          Creating Online Trust: A Professional Website's Approach  
          </h2>
          <p className="text-gray-600 mt-4">
          A professional website conveys trust in two main ways: it exudes credibility and is friendly and communicative in terms of design. It therefore develops an online presence which appears credible and dependable to any visitor.The web composition should depict a decent picture that says brand values and thus guarantees clients that it will change over guests into long-lasting clients.
          </p>
  
          {/* Bullet Points */}
          <ul className="mt-6 space-y-4">
            <li className="flex items-center gap-3">
              <div className="w-6 h-6 bg-orange-500 rounded-full flex items-center justify-center text-white font-bold">
                →
              </div>
              <span className="text-lg sm:text-xl font-bold text-gray-900">
                Evolving Customer Expectations
              </span>
            </li>
            <li className="flex items-center gap-3">
              <div className="w-6 h-6 bg-orange-500 rounded-full flex items-center justify-center text-white font-bold">
                →
              </div>
              <span className="text-lg sm:text-xl font-bold text-gray-900">
                Automated And Paperless Seamless
              </span>
            </li>
          </ul>
  
          {/* Statistics */}
          <div className="mt-8 flex flex-wrap gap-12 justify-center lg:justify-start">
            <div className="text-center">
              <h3 className="text-xl sm:text-2xl font-bold text-gray-900">89%</h3>
              <p className="text-gray-600">Client Satisfied</p>
            </div>
            <div className="text-center">
              <h3 className="text-xl sm:text-2xl font-bold text-gray-900">90%</h3>
              <p className="text-gray-600">Finance Consulting</p>
            </div>
          </div>
        </div>
      </section>
    )
}

export default Professional
