import React from "react";

// Import each logo
import fox from "../../../assets/logo/21st-century-fox-logo.png";
import abbott from "../../../assets/logo/abbott-logo.png";
import adm from "../../../assets/logo/adm-logo.png";
import adobe from "../../../assets/logo/adobe-flash-logo-icon-2.png";
import amazon from "../../../assets/logo/amazon-logo.png";
import walmart from "../../../assets/logo/walmart-logo-2.png";

const ClientLogoSlider = () => {
  const logos = [
    { src: fox, alt: "21st Century Fox" },
    { src: abbott, alt: "Abbott" },
    { src: adm, alt: "ADM" },
    { src: adobe, alt: "Adobe Flash" },
    { src: amazon, alt: "Amazon" },
    { src: walmart, alt: "Walmart" },
  ];

  return (
    <div className="overflow-hidden">
      <div
        className="flex w-[200%]"
        style={{
          animation: "scroll 10s linear infinite",
          whiteSpace: "nowrap",
        }}
      >
        {logos.map((logo, index) => (
          <div key={index} className="flex-shrink-0 w-1/6 h-20 p-4">
            <img src={logo.src} alt={logo.alt} className="h-20 mx-auto" />
          </div>
        ))}
        {/* Repeat the logos for seamless scrolling */}
        {logos.map((logo, index) => (
          <div key={`repeat-${index}`} className="flex-shrink-0 w-1/6 h-20 p-4">
            <img src={logo.src} alt={logo.alt} className="h-20 mx-auto" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientLogoSlider;
