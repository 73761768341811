import React from 'react'
import commonbannerimg from "../assets/commonbannerimg.jpeg"; // Correct import path
import Button from "./common/Button";

const PrivacyPolicy = () => {
    return (
        <div className="bg-gray-50 text-gray-800  mt-[64px]">
            {/* Header Section */}
            <div
                className="bg-cover bg-center text-white h-[300px] md:h-56 p-8 flex flex-col justify-center items-center relative"
                style={{
                    backgroundImage: `url(${commonbannerimg})`,
                }}
            >
                <h1 className="text-4xl font-bold relative z-10">Privacy & Policy</h1>
                <p className="text-sm mt-2 relative z-10">Home /Privacy & Policy</p>
            </div>

            {/* Terms and Conditions Section */}
            <div className="max-w-4xl mx-auto py-8 px-4">
                <h2 className="text-orange-600 text-2xl font-bold mb-4">
                Privacy & Policy
                </h2>
                <p className="text-sm text-gray-500 mb-6">Last Revised: December 16, 2013</p>

                {/* Scrollable Content with Customized Scrollbar */}
                <div className="text-sm text-gray-700 max-h-64 overflow-y-auto p-2 scrollbar-custom">
                    <p>
                        Welcome to www.lorem-ipsum.info. This site is provided as a service to
                        our visitors and may be used for informational purposes only.
                    </p>
                    <p>
                        <strong>1. YOUR AGREEMENT</strong>
                        <br />
                        By using this Site, you agree to be bound by, and to comply with, these
                        Terms and Conditions. If you do not agree to these Terms and Conditions,
                        please do not use this site.
                    </p>
                    <p>
                        <strong>2. PRIVACY</strong>
                        <br />
                        Please review our Privacy Policy, which also governs your visit to this
                        Site, to understand our practices.
                    </p>
                    <p>
                        <strong>3. LINKED SITES</strong>
                        <br />
                        This Site may contain links to other independent third-party websites.
                        These Linked Sites are provided solely as a convenience to our visitors.
                        Such Linked Sites are not under our control, and we are not responsible
                        for and do not endorse their content.
                    </p>
                    <p>
                        <strong>4. MODIFICATIONS</strong>
                        <br />
                        We reserve the right to modify these terms and conditions at any time.
                        Continued use of this site signifies your agreement to the updated terms.
                    </p>
                    <p>
                        <strong>5. GOVERNING LAW</strong>
                        <br />
                        These terms are governed by the laws of the jurisdiction where our
                        company is registered.
                    </p>
                </div>


                {/* Action Buttons */}
                <div className="flex justify-end mt-6">
                    <Button type="submit"
                        // onClick={() => setOpen(!open)}
                        className="bg-white text-custom-orenge     px-4 py-2 rounded-lg hover:bg-red-600 hover:text-white transition"
                        text=" Cancel" />

                    <Button type="submit"
                        // onClick={() => setOpen(!open)}
                        className=" text-custom-orenge ml-3  bg-white  text-center  px-6 py-2 rounded-lg hover:bg-custom-orenge hover:text-white transition"
                        text=" Agree" />
                  
                </div>
            </div>

        </div>
    );
};


export default PrivacyPolicy
