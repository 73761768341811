import React from 'react'

const OfferPlans = () => {
    return (
        <div className="bg-white py-10 mt-[64px]">
          <div className="text-center mb-10">
            <h2 className="text-3xl font-bold text-blue-800 ">Social Media Marketing</h2>
            <h3 className="text-5xl font-bold  text-orange-500">Pricing Plan</h3>
          </div>
    
          <div className="flex flex-col md:flex-row justify-center items-center gap-6">
            {/* 1 Month Plan */}
            <div className="bg-orange-400 text-white rounded-lg shadow-lg p-6 w-80">
              <h4 className="text-2xl font-bold text-center mb-4 text-blue-600">1 MONTH</h4>
              <div className="text-center text-xl font-bold mb-6">
                <span className="line-through text-white">11999/-</span>
                <span className="ml-2">1999/-</span>
              </div>
              <ul className="mb-6 space-y-2 list-disc list-inside">
                <li>Alternate Days Posting</li>
                <li>4 Reels Monthly</li>
                <li>Designing Editing</li>
                <li>Social Media SEO</li>
              </ul>
              <div className="text-center bg-white text-orange-400 font-bold py-2 px-4 rounded-full">
                Business Audit FREE
              </div>
            </div>
    
            {/* 2 Month Plan */}
          
            <div className="bg-blue-800 text-white rounded-lg shadow-lg p-6 w-80 relative">
            <div className="absolute top-0 left-0 bg-yellow-400 text-black font-bold text-sm py-1 px-3 rounded-tr-lg rounded-bl-lg">Most Popular</div>
              <h4 className="text-2xl font-bold text-center mb-4">2 MONTH</h4>
              <div className="text-center text-xl font-bold mb-4">
                <span className="line-through text-gray-200">18999/-</span>
                <span className="ml-2">3499/-</span>
              </div>
              <ul className="mb-6 space-y-2 list-disc list-inside">
                <li>Alternate Days Posting</li>
                <li>6 Reels Monthly</li>
                <li>Designing Editing</li>
                <li>Social Media SEO</li>
                <li>Trending Analysis</li>
              </ul>
              <div className="text-center bg-white text-blue-500 font-bold py-2 px-4 rounded-full mb-3">
             Business Audit FREE
              </div>
              <div className="text-center bg-white text-blue-500 font-bold py-2 px-4 rounded-full mb-3">
                Logo Design FREE
              </div>
              <div className="text-center bg-white text-blue-500 font-bold py-2 px-4 rounded-full mb-3">
                Logo Animation FREE
              </div>
            </div>
    
            {/* 3 Month Plan */}
            <div className="bg-orange-400 text-white rounded-lg shadow-lg p-6 w-80">
              <h4 className="text-2xl font-bold text-center mb-4">3 MONTH</h4>
              <div className="text-center text-xl font-bold mb-6">
                <span className="line-through text-white">4999/-</span>
                <span className="ml-2">4999/-</span>
              </div>
              <ul className="mb-6 space-y-2 list-disc list-inside">
                <li>Alternate Days Posting</li>
                <li>8 Reels Monthly</li>
                <li>Designing Editing</li>
                <li>Social Media SEO</li>
                <li>Trending Analysis</li>
                <li>Competitor Analysis</li>
              </ul>
              <div className="text-center bg-white text-orange-400 font-bold py-2 px-4 rounded-full">
                Meta Ad Campaign FREE
              </div>
            </div>
          </div>
        </div>
      );
    };

export default OfferPlans
