import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../components/LandingPage/Home'
import Layout from './Layout'
import AboutUs from '../components/AboutUs'
import TermsCondition from '../components/TermsCondition'
import PrivacyPolicy from '../components/PrivacyPolicy'
import Services from '../components/Services'
import ContactUs from '../components/ContactUs'
import Blogs from '../components/Blogs'
import Packages from '../components/Packages'
import BlogsPage from '../components/Blogs/BlogsPage'
import OfferPlans from '../components/LandingPage/Home/OfferPlans'


const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Layout> <Home /> </Layout>} />
        <Route path='/about' element={<Layout> <AboutUs /> </Layout>} />
        <Route path='/services' element={<Layout> <Services /> </Layout>} />
        <Route path='/contact' element={<Layout> <ContactUs /> </Layout>} />
        <Route path='/termCondtion' element={<Layout> <TermsCondition /> </Layout>} />
        <Route path='/blogs' element={<Layout> <Blogs/> </Layout>} />
        <Route path='/package' element={<Layout> <Packages/></Layout>} />
        <Route path='/privacyPolicy' element={<Layout> <PrivacyPolicy/> </Layout>} />
        <Route path='/blogsPage' element={<Layout> <BlogsPage/> </Layout>} />
        <Route path='/offer' element={<Layout> <OfferPlans/></Layout>} />








      </Routes>
    </>
  )
}

export default AppRoutes
