import React from "react";

const YourBusiness = () => {
  return (
    <>
      <section className=" bg-custom-brown text-white py-16 px-8">
        {/* Section Container */}
        <div className="max-w-7xl mx-auto grid md:grid-cols-2 gap-8 items-center">
          {/* Left Side */}
          <div>
            <h3 className="text-lg font-semibold text-orange-400 mb-2">
              / Who We Are /
            </h3>
            <h2 className="text-4xl font-bold mb-4">
              Groth Your Business <br /> Expert Sales
            </h2>
            <p className="text-gray-300 mb-8">
              Boost your business with expert sales strategies from Takmon Toost. Drive growth, maximize revenue, and achieve success with our proven, result-oriented solutions tailored for you!
            </p>

            {/* Stats */}
            <div className="flex gap-8 space-x-7">
              {/* Happy Clients */}
              <div className="text-center s">
                <div className="flex items-center justify-center w-28 h-28 rounded-full border-opacity-45 border-white border-2 ">
                  <div className="flex items-center justify-center w-24 h-24 rounded-full border-opacity-75 border-white border-2 ">
                    <div className="flex items-center justify-center w-20 h-20  bg-white text-custom-orenge text-3xl font-bold rounded-full mx-auto">
                      12+
                    </div>
                  </div>
                </div>
                <div className="text-gray-300 text-2xl mt-2">Happy Clients</div>
              </div>

              <div className="text-center">
                <div className="flex items-center justify-center w-28 h-28 rounded-full border-opacity-45 border-white border-2 ">
                  <div className="flex items-center justify-center w-24 h-24 rounded-full border-opacity-75 border-white border-2 ">
                    <div className="flex items-center justify-center w-20 h-20  bg-white text-custom-orenge text-3xl font-bold rounded-full mx-auto">
                      70%
                    </div>
                  </div>
                </div>
                <div className="text-gray-300 text-2xl mt-2">Projects Complete</div>
              </div>

              {/* Projects Complete */}
            </div>
          </div>

          {/* Right Side */}
          <div>
            <div className="space-y-7">
              <div className="px-5 bg-white rounded-bl-full rounded-tr-full pb-0.5 ">
                <div className="bg-gradient-to-r from-orange-500 to-yellow-500 text-black px-4 py-3 rounded-bl-full rounded-tr-full flex items-center">
                  <span className="text-lg font-bold m-2 mx-4 text-white">
                    692% Campaign ROI Exceeds
                  </span>
                </div>
              </div>
              <div className="px-5 bg-white rounded-tl-full rounded-br-full pb-0.5 ">
                <div className="bg-gradient-to-r from-orange-500 to-yellow-500 text-black px-4 py-3 rounded-tl-full rounded-br-full flex items-center">
                  <span className="text-lg font-bold m-2 mx-4 text-white">
                    296% Increase In Organic Traffic
                  </span>
                </div>
              </div>
              <div className="px-5 bg-white rounded-bl-full rounded-tr-full pb-0.5 ">
                <div className="bg-gradient-to-r from-orange-500 to-yellow-500 text-black px-4 py-3 rounded-bl-full rounded-tr-full flex items-center">
                  <span className="text-lg font-bold m-2 mx-4 text-white">
                    8× The Number Of Keywords Sending
                  </span>
                </div>
              </div>
              <div className="px-5 bg-white rounded-tl-full rounded-br-full pb-0.5 ">
                <div className="bg-gradient-to-r from-orange-500 to-yellow-500 text-black px-4 py-3 rounded-tl-full rounded-br-full flex items-center">
                  <span className="text-lg font-bold m-2 mx-4 text-white">
                    Sed Ut Perspiciatis Unde
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div className="w-6 h-6 border-white border-2 rounded-md border-opacity-30 absolute left-10">

      </div>

      </section>
    </>
  );
};

export default YourBusiness;
