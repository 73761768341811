import React from "react";

const Card = ({ title, value, description }) => {
  return (
    <>
    <div className="relative  text-center group">
      {/* Card Container */}
      <div className="bg-white border border-orange-400 rounded-lg text-center p-9 group-hover:shadow-lg transition duration-300 mx-auto h-80 w-80 gap-4  mt-10 ">
      <h3 className="text-xl font-bold text-gray-700 mb-9">{title}</h3>
      <p className="text-4xl font-bold text-orange-600  my-4 mb-9">{value}</p>
      <p className="text-gray-600 text-left">{description}</p>
      </div>

      {/* Button positioned partially outside the card */}
      {/* <button className="absolute bottom-5 left-1/2 transform -translate-x-1/2 translate-y-1/2 bg-orange-400 text-white py-2 px-6 rounded-lg shadow-md group-hover:bg-orange-500 transition duration-300">
        Choose Plan
      </button> */}
    </div>
    </>
  );
};

export default Card;
