import React from "react";
import { FaCode, FaBullhorn, FaPalette, FaSearch } from "react-icons/fa"; // Import React Icons
import commonbannerimg from "../assets/commonbannerimg.jpeg";

import serviceimage from "../assets/Services/serviceimage.jpeg";

const OurServices = () => {
  const services = [
    {
      title: "Software Development",
      description:
        "Harness the power of technology to build scalable and secure applications tailored to your needs.",
      icon: <FaCode />, // React Icon
    },
    {
      title: "Digital Marketing",
      description:
        "Secure great deals and promote your brand with our effective digital marketing strategies.",
      icon: <FaBullhorn />, // React Icon
    },
    {
      title: "Branding",
      description:
        "Stay connected with high-quality creative services that make your brand stand out.",
      icon: <FaPalette />, // React Icon
    },
    {
      title: "SEO",
      description:
        "Navigate the digital landscape with ease using our expert SEO strategies.",
      icon: <FaSearch />, // React Icon
    },
    
  ];

  return (
<div className="bg-gray-50 text-gray-800 mt-[64px] ">
     
     <div
        className="bg-cover bg-center text-white h-[300px] md:h-56 p-8 flex flex-col justify-center items-center relative"
        style={{
          backgroundImage: `url(${commonbannerimg})`,
        }}
      >
        <h1 className="text-4xl font-bold relative z-10">Our Services</h1>
        <p className="text-sm mt-2 relative z-10">Home /Our Servicess</p>
      </div>
      {/* Service Cards */}
      <div className="flex flex-col lg:flex-row justify-center items-center gap-8 py-16 px-6">
        {/* Left Cards */}
     
      
        <div className="flex flex-col gap-8 w-full lg:w-1/3">
          {services.slice(0, 2).map((service, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow hover:shadow-lg transition duration-300"
            >
              <div className="text-4xl text-orange-500 mb-4">{service.icon}</div>
              <h2 className="text-xl font-bold text-gray-800 mb-2">
                {service.title}
              </h2>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>

        {/* Center Image */}
        <div className="w-full lg:w-1/3 flex justify-center">
          <img
            src={serviceimage}
            alt="Our Services"
            className="rounded-lg shadow-lg w-3/4"
          />
        </div>

        {/* Right Cards */}
        <div className="flex flex-col gap-8 w-full lg:w-1/3">
          {services.slice(2).map((service, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow hover:shadow-lg transition duration-300"
            >
              <div className="text-4xl text-orange-500 mb-4">{service.icon}</div>
              <h3 className="text-xl font-bold text-gray-800 mb-2">
                {service.title}
              </h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurServices;
