import React, { useState } from "react";
import Slider from "react-slick";
import "../../App.css"; // Import your custom CSS file

const TeamSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0); // Track the current slide

  const teamMembers = [
    {
      id: 1,
      name: "Larry F. Burnett",
      role: "CEO",
      experience: "4+ years",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCz92zFf37I6HO6kdf3GkyAqvyUPKG7R-fDjZXRtuyb0BzGrDS",
    },
    {
      id: 2,
      name: "Sarah J. Connor",
      role: "CTO",
      experience: "6+ years",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCz92zFf37I6HO6kdf3GkyAqvyUPKG7R-fDjZXRtuyb0BzGrDS",
    },
    {
      id: 3,
      name: "John D. Carter",
      role: "Manager",
      experience: "5+ years",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCz92zFf37I6HO6kdf3GkyAqvyUPKG7R-fDjZXRtuyb0BzGrDS",
    },
    {
      id: 4,
      name: "Alice W. Moore",
      role: "Designer",
      experience: "3+ years",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCz92zFf37I6HO6kdf3GkyAqvyUPKG7R-fDjZXRtuyb0BzGrDS",
    },
    {
      id: 5,
      name: "Michael T. Ross",
      role: "Developer",
      experience: "2+ years",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCz92zFf37I6HO6kdf3GkyAqvyUPKG7R-fDjZXRtuyb0BzGrDS",
    },
  ];

  // Slick slider settings with custom dots
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    beforeChange: (current, next) => setCurrentSlide(next), // Track slide change
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-white py-12">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-orange-500  font-semibold mb-4 text-2xl">
         \ Meet Our Team \
        </h2>
        <h3 className="text-3xl font-bold text-gray-800 mb-8">
          Experienced Team Members
        </h3>
        <Slider {...sliderSettings}>
          {teamMembers.map((member) => (
            <div
              key={member.id}
              className="p-4 bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            >
              <div className="w-full h-64">
                <img
                  src={member.image}
                  alt={member.name}
                  className="w-full h-full object-cover rounded-t-lg"
                />
              </div>
              <div className="bg-gray-50 p-4">
                <h4 className="text-lg font-bold text-gray-800">{member.name}</h4>
                <p className="text-gray-500 text-sm">{member.role}</p>
                <p className="text-gray-500 text-sm">{member.experience}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Custom Dots */}
      <div className="custom-dots-container flex justify-center mt-6">
        {teamMembers.map((_, index) => (
          <div
            key={index}
            className={`custom-dot w-3 h-3 mx-2 rounded-full ${
              currentSlide === index ? "bg-orange-500" : "bg-gray-300"
            } cursor-pointer transition-all`}
            onClick={() => setCurrentSlide(index)} // On dot click, navigate to the respective slide
          />
        ))}
      </div>
    </section>
  );
};

export default TeamSection;
