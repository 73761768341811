import React, { useState } from "react";

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      quote:
        "With Ehya, we're able to easily track our performance in full detail. It’s become an essential tool for us to grow and engage with our audience.",
      name: "Jaquon Hart",
      designation: "Digital Marketing Executive, Hypebeast",
      image: "https://via.placeholder.com/50", // Replace with the actual image URL
      stars: 5,
    },
    {
      id: 2,
      quote:
        "The platform has transformed the way we do business. It has streamlined our processes and allowed us to focus on what truly matters—our customers.",
      name: "Ava Johnson",
      designation: "CEO, Retail Pro",
      image: "https://via.placeholder.com/50", // Replace with the actual image URL
      stars: 4,
    },
    {
      id: 3,
      quote:
        "Ehya's features have made it so much easier for us to manage our operations effectively. I can't imagine going back to the old way of doing things.",
      name: "Ethan Martinez",
      designation: "Operations Manager, TechCraft",
      image: "https://via.placeholder.com/50", // Replace with the actual image URL
      stars: 5,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  const currentTestimonial = testimonials[currentIndex];

  return (
    <section className="bg-white py-16 px-6 sm:px-12 lg:px-24">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
        {/* Left Content */}
        <div className="text-center lg:text-left">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 leading-snug">
            Powering the growth of <br />
            <span className="text-orange-500">100+ business & retailers</span> in
            Indonesia.
          </h2>
          <p className="mt-4 text-sm sm:text-base text-gray-500">
            From single store, startups, to large multi-store brands.
          </p>
        </div>

        {/* Right Testimonial Section */}
        <div className="relative text-center lg:text-left">
          {/* Quotation */}
          <div className="flex justify-center lg:justify-start items-center gap-2">
            <span className="text-2xl font-bold text-gray-600">“</span>
            {[...Array(currentTestimonial.stars)].map((_, index) => (
              <svg
                key={index}
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
                className="w-5 h-5 text-yellow-500"
              >
                <path d="M12 .587l3.668 7.455 8.175 1.192-5.916 5.765 1.396 8.142L12 18.897l-7.323 3.862 1.396-8.142-5.916-5.765 8.175-1.192z" />
              </svg>
            ))}
          </div>
          <p className="mt-4 text-sm sm:text-base text-gray-500 italic">
            {currentTestimonial.quote}
          </p>
          {/* Person Details */}
          <div className="mt-4 flex items-center justify-center lg:justify-start gap-3">
            <img
              src={currentTestimonial.image}
              alt={currentTestimonial.name}
              className="w-12 h-12 rounded-full"
            />
            <div>
              <h4 className="font-bold text-gray-900">{currentTestimonial.name}</h4>
              <p className="text-sm text-gray-500">
                {currentTestimonial.designation}
              </p>
            </div>
          </div>

          {/* Navigation */}
          <div className="flex flex-col items-center lg:items-start gap-2 relative">
            {/* Arrows */}
            <div className="flex items-center justify-center lg:justify-start gap-60 relative -top-10">
              {/* Left Arrow */}
              <div className="relative -left-16"> {/* Adjusted Position */}
                <button
                  onClick={handlePrev}
                  className="w-8 h-8 flex items-center justify-center rounded-full  text-gray-500 hover:text-gray-200 hover:border-orange-500 hover:bg-orange-500"
                >
                  &lt;
                </button>
              </div>

              {/* Right Arrow */}
              <button
                onClick={handleNext}
                className="w-8 h-8 flex items-center justify-center rounded-full  text-gray-500 hover:text-gray-200 hover:border-orange-500 hover:bg-orange-500"
              >
                &gt;
              </button>
            </div>

            {/* Dots */}
            <div className="flex items-center justify-center lg:justify-start gap-1">
              {testimonials.map((_, index) => (
                <span
                  key={index}
                  className={`w-3 h-3 rounded-full ${currentIndex === index
                      ? "bg-orange-500"
                      : "bg-gray-300 hover:bg-orange-500"
                    }`}
                ></span>
              ))}
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Testimonials;
