import React, { useState } from "react";

const Portfolio = () => {
    const [activeTab, setActiveTab] = useState("Digital Marketing");
    const tabs = ["Digital Marketing", "Software Development", "Branding", "SEO"];

    // Portfolio data with 8 images per category
    const portfolioData = {
        "Digital Marketing": [
            { id: 1, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyYUBp-sS7gkr50RwnCu5hElWKdKNuk3eqbw&s", alt: "Social Media Marketing" },
            { id: 2, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTNGSWFXm-nWARkBixWmhbpTm6zc6hFIn40w&s", alt: "Content Strategy" },
            { id: 3, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgvJSB7_QnLc5rnWNw_TxeppKy5q2OCJxYLA&s", alt: "Email Campaigns" },
            { id: 4, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjRg7hvqhV5YZL4tU1l25mV55KiiepP679mw&s", alt: "Analytics and Reporting" },
            { id: 5, src: "https://avigma.com/wp-content/uploads/2020/06/a3.jpg", alt: "SEO Optimization" },
            { id: 6, src: "https://olioglobaladtech.com/wp-content/uploads/2024/03/website-banner-img.png", alt: "Ad Campaigns" },
            { id: 7, src: "https://www.bigtrunk.co.in/wp-content/uploads/2023/01/Digital-marketing-copy.jpg", alt: "PPC Marketing" },
            { id: 8, src: "https://lh3.googleusercontent.com/proxy/QvB5qUC380mc87zZhvq77ICXeQoUbhj6eZVYAYiMMSMYlPWqCII8EnkYTqZgDMw1rLCi_Pg-S-kEemKfbNVD7dWo8godl5aJsQv22Ynvqid9lIofRptmeQYL0yNhSlxc-72NbTz_9M9KPIgUBMoy", alt: "Influencer Marketing" },
        ],
        "Software Development": [
            { id: 1, src: "https://okcredit-blog-images-prod.storage.googleapis.com/2021/03/Software-Development-Business1--1-.jpg", alt: "App Development" },
            { id: 2, src: "https://thumbs.dreamstime.com/b/software-development-business-process-automation-internet-technology-concept-virtual-screen-software-development-173476965.jpg", alt: "Web Development" },
            { id: 3, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfnzemlLlPLlkJMjzYn-JwNI3fXZ-6QH04Wg&s", alt: "Cloud Integration" },
            { id: 4, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR56H_si6dRAa90PtGNzLwiY-Irnctx2m-0Kg&s", alt: "Custom Solutions" },
            { id: 5, src: "https://blog.planview.com/wp-content/uploads/2020/01/Top-6-Software-Development-Methodologies.jpg", alt: "AI Solutions" },
            { id: 6, src: "https://www.simplilearn.com/ice9/free_resources_article_thumb/IT_Engineer_Salary.jpg", alt: "Blockchain Development" },
            { id: 7, src: "https://media.istockphoto.com/id/1502289545/vector/technology-of-software-development-web-design-programming-concept-program-code-on-screen.jpg?s=612x612&w=0&k=20&c=IjkXl6uCuINBjLKmCq95TnhSQaHSAcJE2HzcNK42tv4=", alt: "IoT Integration" },
            { id: 8, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpN7-RenrupG_GbLa_fTDfKWj_q8yFtbfRtw&s", alt: "Software Maintenance" },
        ],
        Branding: [
            { id: 1, src: "https://www.logo-design-india.com/images/logo.png", alt: "Logo Design" },
            { id: 2, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8sb4c--rR3sV_aGkw0MWYEuejwX-u02gfCQ&s", alt: "Brand Identity" },
            { id: 3, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2e7dWuSNj5kMEwnilDS5OYzEqRiM6wsWEFQ&s", alt: "Packaging Design" },
            { id: 4, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTY4bnolsqnqJQ08jIFH104-oWrFIH-QC6HsQ&s", alt: "Visual Marketing" },
            { id: 5, src: "https://c8.alamy.com/comp/T3KF1B/corporate-branding-identity-template-design-modern-colorful-stationery-mockup-business-style-stationery-and-documentation-for-your-brand-vector-T3KF1B.jpg", alt: "Corporate Branding" },
            { id: 6, src: "https://retail-focus.co.uk/wp-content/uploads/2021/09/Cover-Photo.jpg", alt: "Merchandising Design" },
            { id: 7, src: "https://infinityprints.in/cdn/shop/products/8_5ca0b837-f334-4c9a-a839-4ffe34999250.png?v=1673683056", alt: "Business Cards" },
            { id: 8, src: "https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcSDJuRjqHQ9iLKeGm_Up4xHxfyoG59yc3X69f-swewO5c1pOOnZIuzrjxMUuw8H5_D7Mva2mSQdkivJmq0iEq-NPFNjz_1nteM8yZwsu9A", alt: "Promotional Posters" },
        ],
        SEO: [
            { id: 1, src: "https://rootsinstitute.in/wp-content/uploads/2022/04/On-page-seo.png", alt: "On-page SEO" },
            { id: 2, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQM3mwDkPd5t_iSxM1nRcUgLUOy1pkCq6FaGQ&s", alt: "Off-page SEO" },
            { id: 3, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRUcDU4QJrTPBwAYDWGPxrHQ3bVFGaNR8VUw&s", alt: "Technical SEO" },
            { id: 4, src: "https://www.simplilearn.com/ice9/free_resources_article_thumb/how_to_do_KW_research.jpg", alt: "Keyword Research" },
            { id: 5, src: "https://neilpatel.com/wp-content/uploads/fly-images/200550/Content-Optimization-Strategy-008-1200x675-c.webp", alt: "Content Optimization" },
            { id: 6, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLv7PJJk37z6_Q1o_P2W8vGral43KI-Vpz7Q&s", alt: "SEO Audits" },
            { id: 7, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaUXXnrhv9luxdtnrkfj3ArHNsS87Z8PXJcQ&s", alt: "Local SEO" },
            { id: 8, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStVt0RfQlO89lr6uD5_pEPV3NuaEPVIHhxmw&s", alt: "Link Building" },
        ],
    };



    const activeImages = portfolioData[activeTab];

    return (
        <>
        <div className="p-6">
            {/* Section Header */}
            <h2 className="text-center text-2xl sm:text-3xl font-extrabold text-gray-800 mb-4 leading-relaxed">
                <span className="text-orange-500">\ Creative \</span>
                <span className="block">Our Portfolio</span>
            </h2>

            {/* Tabs */}
            <div className="relative">
                {/* Gray baseline */}
                <div className="absolute inset-x-4 sm:inset-x-72 bottom-0 h-[1px] bg-gray-300"></div>

                {/* Tabs */}
                <div className="grid grid-cols-2 gap-3 sm:flex sm:justify-center sm:gap-6  lg:space-x-20 mb-4 relative">
                    {tabs.map((tab) => (
                        <button
                            key={tab}
                            onClick={() => setActiveTab(tab)}
                            className={`text-xs sm:text-sm md:text-lg font-semibold relative pb-2 text-center ${activeTab === tab
                                    ? "text-orange-500"
                                    : "text-gray-600 hover:text-orange-500"
                                }`}
                        >
                            {tab}
                            {/* Dynamic border */}
                            <span
                                className={`absolute bottom-[-1px] left-0 w-full h-[2px] transition-all duration-300 ${activeTab === tab ? "bg-orange-500" : "bg-transparent"
                                    }`}
                            ></span>
                        </button>
                    ))}
                </div>
            </div>


            {/* Portfolio Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 mx-10">
                {activeImages.map((image) => (
                    <div
                        key={image.id}
                        className="rounded-lg overflow-hidden shadow-lg bg-white transition-transform transform hover:scale-105"
                    >
                        <img
                            src={image.src}
                            alt={image.alt}
                            className="w-full h-40 sm:h-52 object-cover"
                        />
                    </div>
                ))}
            </div>
        </div>
        </>
    );
};

export default Portfolio;
