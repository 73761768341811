import React from "react";
import Slider from "react-slick"; // Import react-slick
import {
  FaCode,
  FaPalette,
  FaSearch,
  FaBullhorn,
  FaChartLine,
} from "react-icons/fa";
import "slick-carousel/slick/slick.css"; // Slick Carousel Styles
import "slick-carousel/slick/slick-theme.css"; // Slick Carousel Theme Styles
import { CiDollar } from "react-icons/ci";
import { MdOutlineGroups } from "react-icons/md";
import { GiNotebook } from "react-icons/gi";
import { GiPencilBrush } from "react-icons/gi";
import { LuPenSquare } from "react-icons/lu";
const cardData = [
  {
    title: "Web Development",
    description:
      "Web development refers to the process of building and maintaining websites, which include designing, coding, and functionality in order to create friendly online experiences.",
    icon: <FaCode className="text-orange-500" />,
  },
  {
    title: "Web Design & Development",
    description:
      "Web design services are designed to be visually appealing and functionally well-designed websites, with a focus on design layout, user experience, navigation, and responsiveness in order to meet the clients' goals for web design services.",
    icon: <FaPalette className="text-orange-500" />,
  },
  {
    title: "Branding",
    description:
      "Your brand is what sets you apart. We help in creating a memorable identity for your business.",
    icon: <GiNotebook className="text-orange-500" />,
  },
  {
    title: "SEO",
    description:
      "SEO services include website optimization which promotes increasing visibility, organic traffic increase, better ranking, and total online presence and engagement by users.",
    icon: <FaSearch className="text-orange-500" />,
  },
  {
    title: "SMM",
    description:
      "The SMM service refers to social media networks that are optimized and managed with the intention of promoting the visibility of brands, audience engagement to drive traffic, leads, or sales.",
    icon: <FaBullhorn className="text-orange-500" />,
  },
  {
    title: "Social Media Management",
    description:
      "SMO services include content creation, curation, and scheduling; audience engagement; performance analysis; and brand presence management on multiple social media platforms.",
    icon: <FaChartLine className="text-orange-500" />,
  },
  {
    title: "SMO",
    description:
      "SMO refers to the optimization of the online presence of a brand on social media sites that aids in increasing visibility, targeted traffic, and eventually builds up brand awareness.",
    icon: <MdOutlineGroups className="text-orange-500" />,
  },
  {
    title: "PPC",
    description:
      "PPC services are running online ads where people pay for every click on an advertisement for their business. Their purpose is to drive traffic and visibility for conversion.",
    icon: <CiDollar className="text-orange-500" />,
  },
  {
    title: "Content Marketing",
    description:
      "Content marketing is the process of generating and sharing content that is aimed at acquiring new customers while also delighting existing customers.",
    icon: <LuPenSquare className="text-orange-500" />,
  },
  {
    title: "Graphic Design",
    description:
      "Graphic design is the art of combining text, images, and visual elements in order to communicate messages, ideas, or information effectively and aesthetically in various media formats.",
    icon: <GiPencilBrush className="text-orange-500" />,
  },
  {
    title: "Web Design Services",
    description:
      "Web design services are designed to be visually appealing and functionally well-designed websites, with a focus on design layout, user experience, navigation, and responsiveness in order to meet the clients' goals for web design services.",
    icon: <FaChartLine className="text-orange-500" />,
  },
];

const CardSlider = () => {
  const settings = {
    className: "center",
    centerMode: true,

    dots: true, // Show navigation dots
    infinite: true, // Infinite loop scrolling
    speed: 3000, // Transition speed
    slidesToShow: 3, // Show 3 cards at a time
    slidesToScroll: 1, // Scroll one card at a time
    autoplay: true, // Enable automatic sliding
    autoplaySpeed: 3000, // Set autoplay speed to 3 seconds
    cssEase: "linear",

    focusOnSelect: true, // Select the active card when clicked
    responsive: [
      {
        breakpoint: 1024, // For screen width <= 1024px
        settings: {
          slidesToShow: 2, // Show 2 cards at a time
        },
      },
      {
        breakpoint: 600, // For screen width <= 600px
        settings: {
          slidesToShow: 1, // Show 1 card at a time
        },
      },
    ],
  };

  return (
    <div className=" py-16 overflow-hidden">
      <h2 className="text-3xl font-bold text-center mb-8">Our Services</h2>

      {/* Full-width container for the slider */}
      <div className="relative w-full mx-auto items-center justify-center overflow-hidden" >
        {/* Slider */}
        <Slider {...settings}>
          {cardData.map((card, index) => (
            <div key={index} className="px-4">
              <div className="transform transition-all duration-500 bg-gray-50 w-23 h-64 shadow-lg text-left rounded-lg p-8 group hover:bg-gray-500 hover:scale-95 hover:rounded-2xl overflow-hidden ">
                <div className="text-4xl mb-4 text-left hover:text-orange-500">
                  {card.icon}
                </div>
                <h3 className="text-xl font-semibold mb-2 group-hover:text-white">{card.title}</h3>
                <p className="text-gray-600 text-sm font-light group-hover:text-white overflow-ellipsis overflow-hidden">
                  {card.description}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CardSlider;
