
import React from "react";
import { FaBullhorn, FaLaptopCode, FaCog, FaGlobe, FaAmazon, FaAd } from "react-icons/fa";

// Sample industriesData
const industriesData = [
  {
    icon: <FaBullhorn className="text-4xl text-orange-500" />,
    title: "Digital Marketing",
    description:
      "Digital marketing is a practice of marketing businesses using the internet with SEO, social media, PPC, and email marketing.",
  },
  {
    icon: <FaLaptopCode className="text-4xl text-orange-500" />,
    title: "App Development",
    description:
      "App development is the designing, coding, and launching of new apps for enhancing user experience and functionality.",
  },
  {
    icon: <FaCog className="text-4xl text-orange-500" />,
    title: "Software Development",
    description:
      "Software development is developing custom applications for business needs and streamlines the processes for improved efficiency.",
  },
  {
    icon: <FaGlobe className="text-4xl text-orange-500" />,
    title: "Web Development",
    description:
      "Web development is developing dynamic websites that are user-friendly, and incorporate latest technologies to achieve performance",
  },
  {
    icon: <FaAd className="text-4xl text-orange-500" />,
    title: "Google AdSense",
    description:
      "Google AdSense lets the owners of various websites use their content by publishing relevant ads before an audience.",
  },
  {
    icon: <FaAmazon className="text-4xl text-orange-500" />,
    title: "Amazon Listing",
    description:
      "Amazon listing optimization increases the product's visibility by optimizing its title, description, and keywords to enhance sales.",
  },
  
];

const Industries = () => {
  return (
    <div className="bg-orange-50 py-16 px-5">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h3 className="text-orange-500 font-medium uppercase text-sm mb-2">\ Who We Are /</h3>
        <h2 className="text-3xl md:text-4xl font-bold text-gray-800">
          Helping Businesses in Industries
        </h2>
      </div>

      {/* Grid layout for industries */}
      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 max-w-7xl mx-auto">
        {industriesData.map((industry, index) => (
          <div
            key={index}
            className={`flex flex-col sm:flex-row md:flex-row lg:flex-row items-center gap-4 p-6
           
            `}
          >
            {/* Alternating layout using index */}
            {index % 2 === 0 ? (
              <>
                {/* Left: Text */}
                <div className="flex-1 text-center sm:text-right">
                  <h2 className="text-2xl font-semibold text-gray-800 mb-2">{industry.title}</h2>
                  <p className="text-gray-600  lg:text-xl  md:text-sm font-light">{industry.description}</p>
                </div>
                {/* Right: Icon */}
                <div className="flex justify-center items-center  bg-white border-4 border-orange-500 rounded-full p-4">
                  {industry.icon}
                </div>
              </>
            ) : (
              <>
                {/* Left: Icon */}
                <div className="flex justify-center items-center bg-white border-4 border-orange-500 rounded-full p-4">
                  {industry.icon}
                </div>
                {/* Right: Text */}
                <div className="flex-1 text-center sm:text-left">
                  <h2 className="text-2xl font-semibold text-gray-800 mb-2">{industry.title}</h2>
                  <p className="text-gray-600 text-xl  lg:text-xl  md:text-sm font-light">{industry.description}</p>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Industries;