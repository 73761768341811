import React from 'react';
import commonbannerimg from "../assets/commonbannerimg.jpeg";
import Card from './common/Card';

const Packages = () => {
 const data = [
    {
      title: "Keyword Research",
      value: "12.41",
      description:
        "To find the most persistent and popular keywords for your company, we start by doing extensive keyword research and analysis.",
    },
    {
      title: "High-Quality Backlinks",
      value: "12.41",
      description:
        "This aids the development of a focused and successful SEO strategy by providing premium-quality backlinks.",
    },
    {
      title: "High-Quality Backlinks",
      value: "12.41",
      description:
        "This aids the development of a focused and successful SEO strategy by providing premium-quality backlinks.",
    },
   
  ];
  const data1 = [
    {
      title: "High-Quality Backlinks",
      value: "12.41",
      description:
        "This aids the development of a focused and successful SEO strategy by providing premium-quality backlinks.",
    },
    {
      title: "High-Quality Backlinks",
      value: "12.41",
      description:
        "This aids the development of a focused and successful SEO strategy by providing premium-quality backlinks.",
    },
    {
      title: "High-Quality Backlinks",
      value: "12.41",
      description:
        "This aids the development of a focused and successful SEO strategy by providing premium-quality backlinks.",
    },
   
  ];
  return (
    <>
      <div className=" text-gray-800 mt-[64px]">
        {/* Header Section */}
        <div
          className="bg-cover bg-center text-white h-[300px] md:h-56 p-8 flex flex-col justify-center items-center relative"
          style={{
            backgroundImage: `url(${commonbannerimg})`,
          }}
        >
          <h2 className="text-4xl font-bold relative z-10">Packages</h2>
          <p className="text-sm mt-2 relative z-10">Home / Packages</p>
        </div>

        {/* Packages Section */}
        <div className="text-center py-8 bg-white">
          <h2 className="text-2xl md:text-3xl font-bold mt-2">Our Packages</h2>
        </div>
        <section className="bg-white py-16 px-6 sm:px-12 lg:px-24 ">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {data.map((item, index) => (
            <Card
              key={index}
              title={item.title}
              value={item.value}
              description={item.description}
            />
          ))}
        </div>
        </section>
        <div className="text-center bg-white">
          <h2 className="text-2xl md:text-3xl font-bold mt-2">Our Packages2</h2>
        </div>
        <section className="bg-white py-16 px-6 sm:px-12 lg:px-24">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
          {data1.map((item, index) => (
            <Card
              key={index}
              title={item.title}
              value={item.value}
              description={item.description}
            />
          ))}
        </div>
        </section>
      </div>
    </>
  );
};

export default Packages;
