import React from 'react'
import Banner from './Home/Banner'
import Coustumers from './Home/Coustumers'
import BusinessesIndustries from './Home/BusinessesIndustries'
import YourBusiness from './Home/YourBusiness.jsx'
import Professional from './Home/Professional.jsx'
import Testimonials from './Home/TestimonialsL.jsx'
import CardSlider from './Home/CardSlider.jsx'
import Portfolio from './Home/Portfolio.jsx'
import ContactForm from '../common/ContactForm.jsx'
import ClientLogoSlider from './Home/ClientLogoSlider.jsx'

import { Helmet } from 'react-helmet';
const Home = () => {
  return (
    <>
      <Helmet>
        <title>Takmon Boost - Home</title>
        <meta name="description" content="Welcome to Takmon Boost - Your ultimate growth platform." />
        <meta name="keywords" content="growth, Takmon Boost, businesses, industries" />
      </Helmet>
      <Banner />
      <CardSlider />
      <Coustumers />
      <Professional />
      <ClientLogoSlider />
      <BusinessesIndustries />
      <Portfolio />
      <Testimonials />
      <YourBusiness />
      <ContactForm />
    </>
  )
}

export default Home



