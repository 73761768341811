
import './App.css';

import AppRoutes from "./routes/AppRoutes"




function App() {
  return (
    <div className="">
  <AppRoutes/>
    </div>
  );
}

export default App;
