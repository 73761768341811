import React from "react";
import { LuUsers2 } from "react-icons/lu";
import { BsStar } from "react-icons/bs";
import { LiaRedoAltSolid } from "react-icons/lia";
import statusImage from "../../../assets/statusimage.jpeg";

const statsData = [
  {
    icon: <LuUsers2 className="text-4xl" />, // Icon for Happy Clients
    value: "10,000+",
    label: "Happy Clients",
  },
  {
    icon: <LiaRedoAltSolid className="text-4xl text-center" />, // Icon for Projects Done
    value: "200+",
    label: "Projects Done",
  },
  {
    icon: <BsStar className="text-4xl" />, // Icon for Years of Experience
    value: "4+ Years",
    label: "of Experience",
  },
];

const Coustumers = () => {
  return (
    <div
      className="bg-cover bg-center bg-no-repeat w-full h-auto flex items-center justify-center transition-transform sm:rotate-90 md:rotate-0"
      style={{
        backgroundImage: `url(${statusImage})`,
      }}
    >
      <div className="text-center text-white p-8">
        <h2 className="text-4xl font-bold mb-8">Our Customers</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {statsData.map((stat, index) => (
            <div key={index} className="flex flex-col items-center">
              {/* Centering the icon inside a white circle */}
              <div className="mb-4 bg-white w-28 h-28 md:w-24 md:h-24 flex items-center justify-center rounded-full text-black">
                {stat.icon}
              </div>
              <p className="text-2xl md:text-3xl font-semibold">{stat.value}</p>
              <p className="                       mt-2">{stat.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Coustumers;
