import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/Takmon Boost full.svg";
import commonbannerimg from "../assets/commonbannerimg.jpeg";
import { FaTwitter, FaInstagram, FaLinkedin, FaFacebook } from "react-icons/fa";

const Footer = () => {
  // Scroll to top handler
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  };

  return (
    <footer
      className="bg-cover bg-center text-white p-6 md:p-8 flex flex-col text-xl justify-center items-center relative"
      style={{
        backgroundImage: `url(${commonbannerimg})`,
      }}
    >
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 w-full">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 pt-8 md:pt-16">
          {/* Logo and Address */}
          <div className="text-center md:text-left">
            <img src={logo} alt="Takmon Boost Logo" className="w-24 md:w-32 mx-auto md:mx-0 mb-4" />
            <p>88/89 Peter Odili Road, Port Harcourt, Rivers State.</p>
          </div>

          {/* Navigation Links */}
          <div className="flex flex-row md:flex-row justify-center gap-8 text-xl">
            {/* Column 1 */}
            <div className="flex flex-col space-y-2 md:w-1/2">
              <Link to="/" onClick={handleScrollToTop} className="">
                Home
              </Link>
              <Link to="/services" onClick={handleScrollToTop} className="">
                Services
              </Link>
              <Link to="/about" onClick={handleScrollToTop} className="">
                About
              </Link>
              <Link to="/affiliate" onClick={handleScrollToTop} className="">
              Affiliate marketing
              </Link>
            
            </div>

            {/* Column 2 */}
            <div className="flex flex-col space-y-2 md:w-1/2">
              <Link to="/expertise" onClick={handleScrollToTop} className="">
                Expertise
              </Link>
              <Link to="/blogs" onClick={handleScrollToTop} className="">
                Blog
              </Link>
              <Link to="/privacyPolicy" onClick={handleScrollToTop} className="">
                Privacy Policy
              </Link>
              <Link to="/termCondtion" onClick={handleScrollToTop} className="">
                Term & Condition
              </Link>
              <Link to="/career" onClick={handleScrollToTop} className="">
              Career
              </Link>
            </div>
          </div>

          {/* Social Media Icons */}
          <div className="text-center">
            <h4 className="font-bold mb-2">Connect With Us</h4>
            <div className="flex justify-center space-x-4">
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 transition"
              >
                <FaTwitter size={24} />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 transition"
              >
                <FaInstagram size={24} />
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 transition"
              >
                <FaLinkedin size={24} />
              </a>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 transition"
              >
                <FaFacebook size={24} />
              </a>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-8 text-center text-sm">
          <p>© Jo-Jean Imoh-Ita. All rights reserved. Designed by Takmon Boost</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
