import React from "react";
import commonbannerimg from "../assets/commonbannerimg.jpeg";
import image11 from "../assets/About us/abouts12.jpeg";
import aboutblack from "../assets/About us/aboutblack.jpeg";
import { useNavigate } from "react-router-dom";
import TeamSection from "./common/TeamSlider";
import Button from "./common/Button";


const AboutUs = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    
    console.log("Button clicked!");
    navigate("/contact");
  };
  return (
    <>
    <div className="bg-gray-50 text-gray-800 mt-[64px]">
      {/* Header Section */}
      <div
        className="bg-cover bg-center text-white h-[300px] md:h-56 p-8 flex flex-col justify-center items-center relative"
        style={{
          backgroundImage: `url(${commonbannerimg})`,  // Corrected this line
        }}
      >
        <h1 className="text-4xl font-bold relative z-10">About Us</h1>
        <p className="text-sm mt-2 relative z-10">Home / About Us</p>
      </div>


      {/* About Us Section */}
      <section className="bg-gray-100 py-16 px-8 flex flex-wrap lg:flex-nowrap justify-between items-start gap-8">
        {/* Left Section with Images */}
        <div className="flex flex-col gap-4 w-full lg:w-1/2">
          {/* Image Grid */}
          <div className="flex gap-4 ">
            <img
              src={image11}
              alt="QR Code Scan"
              className="rounded-lg w-full h-auto "
            />
          </div>
        </div>

        {/* Right Section with Text */}
        <div className="flex flex-col justify-start w-full lg:w-1/2 lg:ml-8 lg:mt-20">
          {/* Section Header */}
          <h2 className="text-orange-500 font-semibold text-lg lg:text-xl mb-4 lg:mb-6">
            \ About us \
          </h2>

          {/* Main Title */}
          <h2 className="text-2xl lg:text-4xl font-bold text-gray-900 mb-4 lg:mb-6">
            Company About Us
          </h2>

          {/* Description */}
          <p className="text-gray-700 text-base lg:text-lg leading-relaxed mb-8 lg:mb-10">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been the industry's standard dummy text ever since the
            1500s, when an unknown printer took a galley of type and scrambled it to
            make a type specimen book.
          </p>

          {/* Stats Section */}
          <div className="flex flex-wrap justify-center sm:justify-between gap-4 rounded-lg shadow-md ">
            {/* Happy Clients */}
            <div className="bg-white p-4 sm:p-6 flex flex-col items-center text-center  flex-1">
              <h3 className="text-2xl lg:text-3xl font-bold text-gray-900">99+</h3>
              <p className="text-gray-600 text-sm mt-2 font-bold">Happy Clients</p>
            </div>

            {/* Years in Business */}
            <div className="bg-white p-4 sm:p-6 flex flex-col items-center text-center  flex-1">
              <h3 className="text-2xl lg:text-3xl font-bold text-gray-900">4 Years</h3>
              <p className="text-gray-600 text-sm mt-2 font-bold">Year In Business</p>
            </div>

            {/* Projects Done */}
            <div className="bg-white p-4 sm:p-6 flex flex-col items-center text-center  flex-1">
              <h3 className="text-2xl lg:text-3xl font-bold text-gray-900">25+</h3>
              <p className="text-gray-600 text-sm mt-2 font-bold">Projects Done</p>
            </div>
          </div>

        </div>
      </section>
      <div className="p-40 rounded-lg"><div className="bg-cover bg-center text-white h-[300px] md:h-56 p-8 flex flex-col justify-center items-center relative  rounded-lg"
        style={{
          backgroundImage: `url(${aboutblack})`,  // Corrected this line
        }}
      >
        
        <p className="text-white text-base lg:text-2xl mb-8">
        “Some of the History of Our Company is that
        we are Catching up through Video”
        </p>
        <Button type="submit" text="Get In Touch"  className={"bg-custom-orenge p-2 px-5 rounded-md shadow-md"}   onClick={handleClick}/>
        {/* <img
          src={aboutblack}
          alt="Team Discussion"
          className="rounded-lg w-full h-auto"
        /> */}
      </div></div>
 

        <div>
          <TeamSection/>
        </div>
    </div>
    </>
  );
};

export default AboutUs;