import React from "react";
import mainBlogImage from "../../assets/Blogs/sco1.jpeg";
import smallBlogImage from "../../assets/Blogs/blogspage.jpeg";
import sco1 from "../../assets/Blogs/sco1.jpeg";
import aboutus123 from "../../assets/About us/aboutusimage1-removebg-preview.png";

const BlogsPage = () => {
    return (
        <div className="bg-gray-50 text-gray-800 mt-[64px]">
            {/* Header Section */}
           

            {/* Main Blog Section */}
            
            <div className="max-w-screen-lg mx-auto py-24 px-5 ">
            <div className="relative">
                        <div className="rounded-md absolute w-24 h-24 lg:left-3 lg:bottom-40  md:bottom-24  md:left-6  z-0">
                            <img
                                src={aboutus123}
                                alt="Decoration"
                                className="w-24 h-36"
                            />
                        </div>
                        <div className="rounded-md lg:w-36 lg:h-36 bg-custom-orenge absolute lg:left-12 lg:bottom-20   md:left-14 md:bottom-14 md:w-24 md:h-24 z-10"></div>
                        <div className="relative z-20 px-16">
                            <img
                                src={smallBlogImage}
                                alt={"djfjf"}
                                className={" "}
                            />
                        </div>
                        <div className="rounded-md w-24 h-24 absolute lg:top-[6rem] right-[15px] top-[2rem] z-0">
                            <img
                                src={aboutus123}
                                alt="Decoration"
                                    className="w-24 h-36"
                            />
                        </div>
                        <div className="rounded-md lg:w-36 lg:h-36 md:bottom-10 md:w-32 md:h-32 bg-custom-gray absolute sm:right-[-12px]  lg:right-[50px] lg:top-[4rem]   z-10"></div>
                    </div>
                <h1 className="text-3xl font-bold text-gray-900 mt-14 px-5">
                    10 Reasons to Invest in SEO Copywriting Services
                </h1>
                
                <p className="text-gray-700 text-lg leading-7 px-5">
                    We at Betasaurus provide thorough SEO strategies customized
                    to meet particular requirements of your company. An
                    adaptable system, cutting-edge analytics, and research tools
                    ensure successful strategies that produce results. To
                    guarantee that our clients receive the highest caliber of
                    service, our team of knowledgeable SEO specialists stays
                    current with the most recent trends and best practices in
                    the business. Transparency and communication are integral
                    to us, and we regularly report on progress. We are capable
                    of assisting your company in achieving long-term success
                    through SEO thanks to our experience and commitment to
                    measurable results.
                </p>
            </div>



    
           
        </div>
    );
};

export default BlogsPage;
